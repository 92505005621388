<template>
  <div class="index">
    <div class="content">
      <div class="box-1">
        <div class="box-1-left">
          <el-carousel :interval="5000" height="400px" arrow="never" direction="vertical">
            <el-carousel-item v-for="(item, index) in hot.headimg" :key="index">
              <img :src="item.images[0]" alt="" @click="goShow(item.id)">
              <p class="title">{{ item.title }}</p>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="box-1-right">
          <div v-for="(item, index) in hot.data" :key="index">
            <div v-if="index==0" class="box-1-right-title" @click="goShow(item.id)">{{ item.title }}</div>
            <div v-if="index==0" class="box-1-right-desc">{{ item.intro }}</div>
          </div>
          <div class="box-1-right-list">
            <div v-for="(item, index) in hot.data" :key="index">
              <div class="box-1-right-item" v-if="index>0 && index<7" :style="{backgroundImage:`url(${pointer})`}" @click="goShow(item.id)">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <img class="line" :src="require('@/assets/images/line.jpg')" alt="">
      <div class="box-2">
        <div class="box-2-top">
          <div class="box-item box-left">
            <div class="box-item-header">
              <div class="box-item-header-title">新闻资讯</div>
              <div class="box-item-header-more" @click="goMore(254)">更多 >></div>
            </div>
            <div class="box-item-list">
              <div v-for="(item, index) in xwzx" :key="index" @click="goShow(item.id)">
                <div class="box-item-item" v-if="index<7" :style="{backgroundImage:`url(${pointer})`}">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <div class="box-item box-right">
            <div class="box-item-header">
              <div class="box-item-header-title">党建动态</div>
              <div class="box-item-header-more" @click="goMore(242)">更多 >></div>
            </div>
            <div class="box-item-list">
              <div v-for="(item, index) in djdt" :key="index" @click="goShow(item.id)">
                <div class="box-item-item" v-if="index<7" :style="{backgroundImage:`url(${pointer})`}">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-2-bottom">
          <div class="box-item box-left">
            <div class="box-item-header">
              <div class="box-item-header-title">学习园地</div>
              <div class="box-item-header-more" @click="goMore(243)">更多 >></div>
            </div>
            <div class="box-item-list">
              <div v-for="(item, index) in xxyd" :key="index" @click="goShow(item.id)">
                <div class="box-item-item" v-if="index<7" :style="{backgroundImage:`url(${pointer})`}">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <div class="box-item box-right">
            <div class="box-item-header">
              <div class="box-item-header-title">党员E家</div>
              <div class="box-item-header-more" @click="goMore(245)">更多 >></div>
            </div>
            <div class="box-item-list">
              <div v-for="(item, index) in dyej" :key="index" @click="goShow(item.id)">
                <div class="box-item-item" v-if="index<7" :style="{backgroundImage:`url(${pointer})`}">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <img class="ad" :src="require('@/assets/images/ad.jpg')" alt="">
      <div class="box-3">
        <div class="box-item">
          <div class="box-item-header">
            <div class="box-item-header-title">喜迎20大</div>
            <div class="box-item-header-more" @click="goMore(244)">更多 >></div>
          </div>
          <div class="box-item-image" v-for="(item, index) in xy20d" :key="index" @click="goShow(item.id)">
            <img v-if="index==0" :src="item.images[0]" alt="">
            <div v-if="index==0" class="box-item-image-title">{{ item.title }}</div>
          </div>
          <div class="box-item-list">
            <div v-for="(item, index) in xy20d" :key="index">
              <div class="box-item-item" v-if="index>0 && index<5" @click="goShow(item.id)" :style="{backgroundImage:`url(${pointer})`}">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="box-item">
          <div class="box-item-header">
            <div class="box-item-header-title">会员风采</div>
            <div class="box-item-header-more" @click="goMore(248)">更多 >></div>
          </div>
          <div class="box-item-image" v-for="(item, index) in hyfc" :key="index" @click="goShow(item.id)">
            <img v-if="index==0" :src="item.images[0]" alt="">
            <div v-if="index==0" class="box-item-image-title">{{ item.title }}</div>
          </div>
          <div class="box-item-list">
            <div v-for="(item, index) in hyfc" :key="index">
              <div class="box-item-item" v-if="index>0 && index<5" @click="goShow(item.id)" :style="{backgroundImage:`url(${pointer})`}">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="box-item">
          <div class="box-item-header">
            <div class="box-item-header-title">视频信息</div>
            <div class="box-item-header-more" @click="goPosition(66)">更多 >></div>
          </div>
          <div class="box-item-image" v-for="(item, index) in spxx" :key="index" @click="goShow(item.id)">
            <img v-if="index==0" :src="item.images[0]" alt="">
            <div v-if="index==0" class="box-item-image-title">{{ item.title }}</div>
          </div>
          <div class="box-item-list">
            <div v-for="(item, index) in spxx" :key="index">
              <div class="box-item-item" v-if="index>0 && index<5" @click="goShow(item.id)" :style="{backgroundImage:`url(${pointer})`}">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <img class="ad" :src="require('@/assets/images/ad1.jpg')" alt="">
      <div class="box-4">
        <div class="box-4-header">
          <div class="box-4-header-title">专题专栏</div>
          <div class="box-4-header-more" @click="goPosition(67)">更多 >></div>
        </div>
        <div class="box-4-images">
          <div class="box-4-item" v-for="(item, index) in ztzl" :key="index" @click="goSpecial(item.skip_special_id)">
            <img :src="item.images[0]" alt="">
            <div class="box-4-item-title">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="flink">
        <div class="flink-item"><a target="_blank" href="http://www.cac.gov.cn/">中华人民共和国国家互联网信息办公室</a></div>
        <div class="flink-item"><a target="_blank" href="http://www.mca.gov.cn/">中华人民共和国民政部</a></div>
        <div class="flink-item"><a target="_blank" href="http://www.people.com.cn/">人民网</a></div>
        <div class="flink-item"><a target="_blank" href="http://www.news.cn/">新华网</a></div>
        <div class="flink-item"><a target="_blank" href="http://www.china.com.cn/">中国网</a></div>
        <div class="flink-item"><a target="_blank" href="https://www.gmw.cn/">光明网</a></div>
        <div class="flink-item"><a target="_blank" href="http://www.cfis.cn/">中网联</a></div>
        <div class="flink-item"><a target="_blank" href="http://www.guiwang.org/">桂网</a></div>
        <div class="flink-item"><a target="_blank" href="https://www.gxjubao.org.cn/">广西举报</a></div>
        <div class="flink-item"><a target="_blank" href="http://ipv6.guiwang.org/">IPv6监测</a></div>
      </div>
      <div class="float">
        <div class="float-box">
          <p>广西联合会公众号</p>
          <img :src="require('@/assets/images/qr1.png')" alt="">
        </div>
        <div class="float-box">
          <p>我们的同心云APP</p>
          <img :src="require('@/assets/images/qr2.png')" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pointer from '@/assets/images/pointer.jpg'
export default {
  name: "Index",
  data() {
    return {
      pointer: pointer,
      hot: [],
      xwzx: [],
      djdt: [],
      xxyd: [],
      dyej: [],
      xy20d: [],
      hyfc: [],
      spxx: [],
      ztzl: [],
    };
  },
  mounted () {
    this.getHot()
    this.getList(254);
    this.getList(242);
    this.getList(243);
    this.getList(245);
    this.getList(244);
    this.getList(248);
    this.getPosition(66);
    this.getPosition(67);
  },
  methods: {
    goShow(id) {
      this.$router.push({
        name: "Show",
        params: {
          id: id
        }
      });
    },
    goPosition(id) {
      this.$router.push({
        name: "Position",
        params: {
          id: id
        }
      });
    },
    goSpecial(id) {
      this.$router.push({
        name: "Special",
        params: {
          id: id
        }
      });
    },
    goMore(id) {
      this.$router.push({
        name: "Lists",
        params: {
          id: id
        }
      });
    },
    getHot() {
      let that = this
      this.$api.get('/news/position/appid/358/columnid/65/page/1', {}).then(function (response) {
        that.hot = response.data
      }).catch(function (error) {
        console.log(error);
      });
    },
    getList(id) {
      let that = this
      this.$api.get('/news/list/appid/358/columnid/'+id+'/page/1', {}).then(function (response) {
        switch (id) {
          case 254:
            that.xwzx = response.data.data
            break;
          case 242:
            that.djdt = response.data.data
            break;
          case 243:
            that.xxyd = response.data.data
            break;
          case 245:
            that.dyej = response.data.data
            break;
          case 244:
            that.xy20d = response.data.data
            break;
          case 248:
            that.hyfc = response.data.data
            break;
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    getPosition(id) {
      var pagesize = 5
      if(id==67){
        pagesize = 4
      }
      let that = this
      this.$api.get('/news/position/appid/358/columnid/'+id+'/pagesize/'+pagesize+'/page/1', {}).then(function (response) {
        switch (id) {
          case 66:
            that.spxx = response.data.data
            break;
          case 67:
            that.ztzl = response.data.data
            break;
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
  }
};
</script>

<style scoped>
  .float{position: absolute;right:-170px;top:20px;width:150px;background: #fff;}
  .float p{background: #4384f6;width:100%;height:40px;line-height: 40px;text-align: center;color:#fff;font-size:14px;}
  .float img{width:130px;margin:10px 10px;}
  .content{width:1280px;background: #fff;margin:0 auto;position: relative;}
  .el-carousel__item img{cursor: pointer;}
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 400px;
    margin: 0;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .el-carousel__container{height:400px;}
  .content{padding:20px;box-sizing: border-box;}
  .box-1-left{width:700px;height:400px;float:left;position: relative;}
  .box-1-left .title{position: absolute;bottom:0;left:0;width:100%;height:40px;line-height:40px;font-size:16px;background: rgb(0 0 0 /70%);color:#fff;padding:0 10px;box-sizing: border-box;}
  .box-1-left img{width:100%;height:400px;}
  .box-1-right{width:520px;height:400px;float:right;}
  .box-1-right-title{font-size:20px;font-weight:bold;color:#4384f6;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow: hidden;cursor: pointer;}
  .box-1-right-desc{font-size:14px;color:#999;margin:15px 0 10px 0;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;overflow: hidden;line-height:25px;}
  .box-1-right-list{border-top:2px solid #eeeeee;padding-top:10px;}
  .box-1-right-item{display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow: hidden;font-size:16px;color:#333;background-repeat: no-repeat;background-position: left center;padding-left:20px;height:40px;line-height:40px;cursor: pointer;}
  .line{width:100%;margin:40px 0;box-sizing: border-box;}
  .box-2 .box-item{width:600px;height:380px;}
  .box-left{float:left;}
  .box-right{float:right;}
  .box-item-header{border-bottom:1px solid #4384f6;height:60px;}
  .box-item-header-title{font-size:25px;font-weight:bold;color:#333;border-left:5px solid #4384f6;height:30px;line-height:30px;margin:15px 0;float:left;padding-left:15px;}
  .box-item-header-more{float:right;padding-top:20px;font-size:14px;color:#787878;cursor: pointer;}
  .box-item-list{margin:15px 0;}
  .box-item-item{background-repeat: no-repeat;background-position: left center;padding-left:20px;height:40px;line-height:40px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow: hidden;font-size:15px;cursor: pointer;}
  .clear{clear: both;;}
  .ad{width:100%;margin:20px 0;}
  .box-3{margin-top:20px;}
  .box-item-image{position: relative;cursor: pointer;}
  .box-item-image img{width:100%;height:200px;}
  .box-item-image-title{position: absolute;bottom:0;left:0;width:100%;height:40px;line-height:40px;font-size:16px;background: rgb(0 0 0 /70%);color:#fff;padding:0 10px;box-sizing: border-box;}
  .box-3 .box-item{width:390px;height:420px;display:inline-block;margin-right:35px;}
  .box-3 .box-item:last-child{margin-right:0;}
  .box-4-header{border-bottom:1px solid #4384f6;height:60px;margin-bottom:20px;}
  .box-4-header-title{font-size:25px;font-weight:bold;color:#4384f6;border-left:5px solid #4384f6;height:30px;line-height:30px;margin:15px 0;float:left;padding-left:15px;}
  .box-4-item{width:290px;height:170px;position: relative;display:inline-block;margin-right:25px;cursor: pointer;}
  .box-4-item:last-child{margin-right:0;}
  .box-4-item-title{position: absolute;bottom:0;left:0;width:100%;height:40px;line-height:40px;font-size:16px;background: rgb(0 0 0 /70%);color:#fff;padding:0 10px;box-sizing: border-box;}
  .box-4-header-more{float:right;padding-top:20px;font-size:14px;color:#787878;cursor: pointer;}
  .box-4-item img{height:100%;}
  .flink{background: #4384f6;padding:20px 30px;margin-top:30px;}
  .flink-item{display: inline-block;font-size:16px;margin-right:20px;}
  .flink-item a{color:#fff;}
  .el-carousel__indicators--horizontal{position: absolute;right:0;bottom:0;}
</style>