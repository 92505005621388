import axios from 'axios'
import lstore from '@/plugins/lstore/lstore.js'

let cancel
const pending = {}

const baseURL = 'http://www.sogx.cn/api'
// const baseURL = '/api'

const instance = axios.create({
  baseURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'lhxtd123456789',
    'From': 1
  },
})

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 发起请求时，取消掉当前正在进行的相同请求
    if (pending[config.url]) {
      pending[config.url] = cancel
    } else {
      pending[config.url] = cancel
    }
    const TOKEN = lstore.getData('LHXTD_ACCESS_TOKEN')
    if (TOKEN) {
      config.headers.Authorization = TOKEN
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

export default instance
