<template>
  <div class="lists">
    <div class="title" :style="{backgroundImage:`url(${title})`}">新闻资讯</div>
    <div class="lists-box" v-loading="loading">
        <div class="lists-item" v-for="(item, index) in lists" :key="index">
            <img @click="goShow(item.id)" v-if="item.images[0]" :src="item.images[0]" alt="">
            <div class="lists-item-content">
                <div class="lists-item-title" @click="goShow(item.id)">{{ item.title }}</div>
                <div class="lists-item-desc" v-html="item.intro"></div>
                <div class="line">
                    <div class="time" :style="{backgroundImage:`url(${time})`}"><span>发布时间：{{ item.ptime }}</span></div>
                    <div class="more" @click="goShow(item.id)">查看详情</div>
                </div>
            </div>
        </div>
        <div class="page" v-if="lists.length>0">
            <el-pagination
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :current-page="page"
                :total="total">
            </el-pagination>
        </div>
    </div>
  </div>
</template>

<script>
import time from '@/assets/images/time.jpg'
import title from '@/assets/images/list-bg.jpg'
export default {
  name: "Lists",
  data() {
    return {
      time: time,
      title: title,
      page: 1,
      lists: [],
      total: 0,
      loading: false,
    };
  },
  created () {
    this.getLists();
  },
  watch:{
    //监测路由变化
    '$route'(){
      this.page = 1;
      this.getLists();
    }
  },
  methods: {
    // 处理改变当前页数
    handleCurrentChange(val) {
      this.page = val;
      this.getLists();
    },
    goShow(id) {
      this.$router.push({
        name: "Show",
        params: {
          id: id
        }
      });
    },
    getLists() {
      let that = this
      this.loading = true;
      this.$api.get('/news/list/appid/358/columnid/'+this.$route.params.id+'/pagesize/10/page/'+this.page, {}).then(function (response) {
        that.loading = false;
        that.lists = response.data.data
        that.total = response.data.total
      }).catch(function (error) {
        that.loading = false;
        console.log(error);
      });
    },
  }
};
</script>

<style scoped>
  .title{background-repeat: no-repeat;background-position: left bottom;padding-bottom:20px;font-size:24px;font-weight: bold;color:#568ef7;margin:20px 0 30px 0;}
  .lists{width:1280px;margin:0 auto;padding:20px;background: #fff;box-sizing: border-box;}
  .lists-item{height:200px;position: relative;border-bottom:1px solid #f2f2f2;padding-bottom:25px;margin:25px 0;}
  .lists-item img{width:260px;height:200px;float:left;margin-right:20px;cursor: pointer;}
  .lists-item-title{font-size:20px;color:#333;padding:15px 0;font-weight: bold;cursor: pointer;}
  .lists-item-desc{color:#666;font-size:14px;line-height:25px;min-height:115px;}
  .time{float:left;font-size:14px;color:#999;background-repeat: no-repeat;background-position: left center;padding-left:20px;}
  .time span{margin-right:30px;}
  .more{padding:8px 20px;background: #4384f6;color:#fff;border-radius: 5px;position: absolute;right:0;bottom:25px;cursor: pointer;}
  .page{text-align: center;}
</style>