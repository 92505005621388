<template>
  <div id="app">
    <div class="header">
      <div class="header-top" :style="{backgroundImage:`url(${bannerBg})`}">
        <div class="header-box" :style="{backgroundImage:`url(${banner})`}">
          <div class="logo-box">
            <img class="logo" :src="require('@/assets/images/logo.png')" alt="">
          </div>
          <div class="search-box">
            <el-input placeholder="输入关键词搜索" v-model="keyword" class="input-with-select">
              <el-button slot="append" type="primary" icon="el-icon-search" @click="doSearch"></el-button>
            </el-input>
          </div>
        </div>
      </div>
      <div class="header-nav">
        <div class="list">
          <div :class="current==item.columnid ? 'item item-on' : 'item'" v-for="(item, index) in nav" :key="index" @click="goLists(item.columnid)">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <router-view/>
    <div class="footer">
      <!-- <div><span>关于我们</span>|<span>联系我们</span>|<span>版权声明</span></div> -->
      <div><span>违法和不良信息举报电话：0771-5690995</span></div>
      <div><span>电子邮件：gxlhh666@163.com</span><span>电话：0771-3132055</span><span><a target=_blank href="https://beian.miit.gov.cn/">桂ICP备2020008031号-4</a></span></div>
    </div>
  </div>
</template>

<script>
import banner from '@/assets/images/banner.jpg'
import bannerBg from '@/assets/images/banner-bg.png'
export default {
  name: "App",
  data() {
    return {
      banner: banner,
      bannerBg: bannerBg,
      keyword: '',
      nav: [],
    };
  },
  computed: {
    current () {
      let current = 0;
      if(this.$route.name=='Lists'){
        current = this.$route.params.id
      }
      return current;
    },
  },
  mounted () {
    this.getNav()
  },
  methods: {
    goLists(id) {
      if(id>0){
        this.$router.push({
          name: "Lists",
          params: {
            id: id
          }
        });
      }else{
        this.$router.push({
          name: "Index",
        });
      }
    },
    doSearch() {
      if(this.keyword==''){
        this.$message({
          message: '关键字不能为空',
          type: 'warning'
        });
      }else{
        this.$router.push({
          name: "Search",
          params: {
            keyword: this.keyword
          }
        });
      }
    },
    getNav() {
      let that = this
      this.$api.get('/app/navigation/appid/358/foot/0/client_type/2', {}).then(function (response) {
        that.nav = response.data.data
      }).catch(function (error) {
        console.log(error);
      });
    },
  }
};
</script>

<style>
  #app {font-family: Avenir, Helvetica, Arial, sans-serif;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
  body{background: #f5f5f5;}
  .header-top{position: relative;}
  .header-box{width:1280px;height:230px;margin:0 auto;position: relative;}
  .logo-box{width:1280px;margin:0 auto;}
  .logo{margin-top:50px;}
  .header-nav{width:100%;background: #4384f6;}
  .list{width:1200px;margin:0 auto;background: #4384f6;}
  .list .item{display:inline-block;width:120px;height:65px;line-height:65px;text-align: center;color:#fff;background: #4384f6;cursor: pointer;}
  .list .item-on{color:#4384f6;background: #fff;}
  .footer{width:1280px;margin:0 auto;padding:20px;background: #fff;box-sizing: border-box;border-top:1px solid #cacaca;}
  .footer>div{margin:10px 0;}
  .footer span{margin:0  10px;}
  .search-box{width:260px;height:40px;position: absolute;right:0;top:75px;}
</style>
