<template>
  <div class="lists" v-loading="loading">
    <div class="breadcrumb">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/position/67' }">专题专栏</el-breadcrumb-item>
            <el-breadcrumb-item>{{ info.title }}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <img class="banner" :src="info.banner" alt="" v-if="info.banner">
    <div class="intro" v-if="info.intro">
      <img :src="require('@/assets/images/title.png')" alt="">
      <p>{{ info.intro }}</p>
    </div>
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="info.group.length>1 ? item.name : '新闻列表'" :name="'t'+index" v-for="(item, index) in info.group" :key="index">
          <div class="lists-box">
              <div class="lists-item" v-for="(item1, index1) in item.items" :key="index1">
                  <img @click="goShow(item.id)" v-if="item1.images.length>0" :src="item1.images[0]" alt="">
                  <div class="lists-item-content">
                      <div class="lists-item-title" @click="goShow(item1.id)">{{ item1.title }}</div>
                      <div class="lists-item-desc" v-html="item1.intro"></div>
                      <div class="line">
                          <div class="time" :style="{backgroundImage:`url(${time})`}"><span>发布时间：{{ item1.ptime }}</span></div>
                          <div class="more" @click="goShow(item1.id)">查看详情</div>
                      </div>
                  </div>
              </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import time from '@/assets/images/time.jpg'
import title from '@/assets/images/list-bg.jpg'
export default {
  name: "Position",
  data() {
    return {
      time: time,
      title: title,
      info: [],
      loading: false,
      activeName: 't0'
    };
  },
  created () {
    this.getSpecial();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    goShow(id) {
      this.$router.push({
        name: "Show",
        params: {
          id: id
        }
      });
    },
    getSpecial() {
      let that = this
      this.loading = true;
      this.$api.get('/special/list/appid/358/id/'+this.$route.params.id+'/page/1', {}).then(function (response) {
        that.loading = false;
        that.info = response.data.data
      }).catch(function (error) {
        that.loading = false;
        console.log(error);
      });
    },
  }
};
</script>

<style scoped>
  .title{background-repeat: no-repeat;background-position: left bottom;padding-bottom:20px;font-size:24px;font-weight: bold;color:#568ef7;margin:20px 0 30px 0;}
  .lists{width:1280px;margin:0 auto;padding:20px;background: #fff;box-sizing: border-box;}
  .lists-item{height:200px;position: relative;border-bottom:1px solid #f2f2f2;padding-bottom:25px;margin:25px 0;}
  .lists-item img{width:260px;height:200px;float:left;margin-right:20px;cursor: pointer;}
  .lists-item-title{font-size:20px;color:#333;padding:15px 0;font-weight: bold;cursor: pointer;}
  .lists-item-desc{color:#666;font-size:14px;line-height:25px;min-height:115px;}
  .time{float:left;font-size:14px;color:#999;background-repeat: no-repeat;background-position: left center;padding-left:20px;}
  .time span{margin-right:30px;}
  .more{padding:8px 20px;background: #4384f6;color:#fff;border-radius: 5px;position: absolute;right:0;bottom:25px;cursor: pointer;}
  .page{text-align: center;}
  .banner{width:100%;height:230px;}
  .breadcrumb{margin-bottom:20px;}
  .intro{position: relative;padding:35px;background: #F3F3F3;margin-top:20px;color:#333;line-height:25px;text-indent: 2em;}
  .intro img{position: absolute;left:0;top:0;}
  .tabs{margin:20px 5px;}
</style>