import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Lists from '../views/lists.vue'
import Position from '../views/Position.vue'
import Special from '../views/Special.vue'
import Search from '../views/search.vue'
import Show from '../views/show.vue'


const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      title: '首页',
    },
  },
  {
    path: '/lists/:id(\\d+)',
    name: 'Lists',
    component: Lists,
    meta: {
      title: '列表页',
    },
  },
  {
    path: '/position/:id(\\d+)',
    name: 'Position',
    component: Position,
    meta: {
      title: '推荐页',
    },
  },
  {
    path: '/special/:id(\\d+)',
    name: 'Special',
    component: Special,
    meta: {
      title: '推荐页',
    },
  },
  {
    path: '/search/:keyword',
    name: 'Search',
    component: Search,
    meta: {
      title: '搜索页',
    },
  },
  {
    path: '/show/:id(\\d+)',
    name: 'Show',
    component: Show,
    meta: {
      title: '内容页',
    },
  },
]

const router = new VueRouter({routes})

export default router
