<template>
    <div class="show">
        <div class="breadcrumb">
            <el-breadcrumb separator=">">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/">内容</a></el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box" v-loading="loading">
            <div class="title">{{ data.title }}</div>
            <div class="time"><span>{{ data.ptime }}</span><span v-if="data.copyfrom">来源：{{ data.copyfrom }}</span></div>
            <div class="video" v-if="data.video_url">
              <video
                id="myVideo"
                class="video-js vjs-default-skin"
                controls
                autoplay
                preload="auto"
                width="800px"
              ></video>
            </div>
            <div class="content" v-html="data.content"></div>
        </div>
    </div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
  name: "Show",
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  mounted () {
    this.getShow();
  },
  methods: {
    getVideo(video) {
      var type = '';
      if(video.indexOf(".m3u8")!=-1){
        type = 'application/x-mpegURL'
      }else{
        type = 'video/mp4'
      }
      var myPlayer = videojs("myVideo");
      myPlayer.src([{src:video,type:type}]);
      this.$nextTick(() => {
        myPlayer.play();
      })
    },
    getShow() {
      let that = this
      this.loading = true;
      this.$api.get('/news/detail/appid/358/id/'+this.$route.params.id, {}).then(function (response) {
        that.loading = false;
        that.data = response.data.data
        if(that.data.video_url!=''){
          that.$nextTick(() => {
            that.getVideo(that.data.video_url);
          })
        }
      }).catch(function (error) {
        that.loading = false;
        console.log(error);
      });
    },
  }
};
</script>

<style scoped>
  .breadcrumb{margin:20px 0 0 20px;}
  .show{width:1280px;margin:0 auto;padding:20px;background: #fff;box-sizing: border-box;}
  .box{padding:50px 100px;}
  .title{font-size:30px;font-weight: bold;color:#333;text-align: center;}
  .time{text-align: center;color:#999;margin:20px 0;font-size:16px;}
  .time span{margin-right:30px;}
  .content{line-height:30px;}
  .video{width:800px;margin:20px auto;text-align: center;}
</style>